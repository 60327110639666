import "@arco-design/web-react/es/Message/style";
import _Message from "@arco-design/web-react/es/Message";
import axios from "axios";
import history from "@/history";
const ACCESS_TOKEN = "dgbj_token";
const api_url = {
  "product": "https://api.dinggebaojian.com/api",
  "dev": "https://djbj.deer.funengyun.cn/api"
};
const baseURL = process.env.NODE_ENV === "development" ? api_url.dev : api_url.product;
const request = axios.create({
  baseURL,
  headers: {
    "content-type": "application/json"
  },
  timeout: 60000
});
export { baseURL };
const noValidation = ['/server_admin/dataLogin'];
request.interceptors.request.use(config => {
  if (noValidation.includes(config.url)) {
    return config;
  }

  const token = localStorage.getItem(ACCESS_TOKEN);

  if (!token) {
    _Message.error("请先登录");

    setTimeout(() => {
      window.location.pathname = '/login';
    }, 2000);
    return;
  }

  token && (config.headers[ACCESS_TOKEN] = token);
  return config;
});
request.interceptors.response.use(response => {
  const err_msg = response.data.msg || response.data.message || "服务器错误";

  if (response.data.code === -9999) {
    _Message.error(err_msg);

    setTimeout(() => {
      history.push("/login");
    }, 1000);
    return;
  }

  return response.data;
});
export default request;